<template>
    <div class="contract-detail">
        <!-- 自定义头部 -->
        <div class="comp-navbar">
            <!-- <img  class="header-bg" src="@/assets/yq/header-bg.png"></img> -->
            <!-- 占位栏 -->
            <div
                class="placeholder-bar"
                :style="{ height: navBarHeight  + 'px' }"
            >
                <img
                    class="header-bg"
                    src="@/assets/yq/index-header-bg-02.png"
                />
                <div
                    class="bar-nav"
                    :style="{ height: navBarHeight + 'px' }"
                >
                    <!-- <div
                        class="nav-statusbar"
                        :style="{ height: statusBarHeight + 'px' }"
                    >
                    </div> -->
                    <div
                        class="nav-titlebar"
                        :style="{ height: titleBarHeight + 'px' }"
                    >
                        <img @click="backClick"
                            class="img"
                            src="@/assets/yq/back.png"
                        />
                        <div class="text">
                            合同详情
                        </div>
                    </div>
                </div>
                <div class="part-circle"> </div>
            </div>
        </div>

        <div class="contract-card bg-fff">
            <div class="title">
                <div>{{ detailObj.name }}</div>
            </div>
            <div class="info">合同编号：{{ detailObj.code }}</div>
            <div class="info">证书信息：{{ detailObj.productName }}</div>
            <div class="info">
                合同金额：<span>{{ detailObj.amount }}</span>
            </div>
            <div class="info">合同有效期：{{ detailObj.expiresIn }}</div>
            <div class="pc">
                <img src="@/assets/yq/wait-sign-status.png" />
                <div>人才合同</div>
            </div>

            <!-- <div class="no-wait" v-show="contractObj.status!=0">
                <img :src="'@/assets/yq/contract-status-'+contractObj.status+'.png'" >
                <img src="@/assets/yq/contract-status-2.png" >
            </div> -->
            <div class="opt flex">
                <div @click.stop="getContractUrl()">
                    <img src="@/assets/yq/contract-icon.png" />
                    <div class="opt-title">电子合同</div>
                </div>
                <div @click="callTelephone">
                    <a :href="'tel:' + detailObj.employeeMobile">
                        <img src="@/assets/yq/customer-icon.png" />
                        <div class="opt-title">{{ detailObj.employeeName}}</div>
                    </a>
                    <!-- <span>(专属客服)</span> -->
                </div>
            </div>
        </div>
        <!--  -->
        <!-- <div class="contract-process bg-fff margin-top-xs">
            <div class="sign-process">
                <div class="top flex">
                    <img src="@/assets/yq/contract-detail-03.png" >
                    <span>签约流转</span>
                </div>
                <div class="step"></div>
                <div class="step-new">
                    <div
                        class="step-item flex"
                        v-for="(item, index) in flowList"
                        :key="index"
                    >
                        <div class="step-line">
                            <div class="step-ico"></div>
                            <div class="line"></div>
                        </div>
                        <div class="text">
                            <div class="title">
                                {{ item.createTime }}
                            </div>
                            <div
                                class="content"
                                v-html="item.flowMessage"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!--  -->
        <!-- <div class="option flex">
            <div class="complaint" @click="showTextModal">
                <span>投诉</span>
            </div>
            <div class="opinion" @click="goMineOpinion">
                <span>反馈意见</span>
            </div>
        </div> -->
        <!-- <complaintModal
            :isShow="textModal"
            @cancel="handleCancel"
            @confirm="handleTextConfirm"
        /> -->
        <!-- <complaintConfirm
            :isShow="confirmModal"
            @cancel="handleConfirmCancel"
        /> -->
    </div>
</template>

<script>
// import complaintModal from "@/components/complaintModal.vue";
// import complaintConfirm from "@/components/complaintConfirm.vue";
import { getContractById, getContractFile } from "@/api/contract";
import { sendComplaint } from "@/api/complaint";
import config from "@/config/config";
import { regFenToYuan } from '@/utils/format/unit';
import { parseTime } from '@/utils/index';

export default {
    components: {
        // complaintModal,
        // complaintConfirm,
    },
    data() {
        return {
            id: "", //每条新闻资讯的id
            headerTitle: "合同详情",
            verticalCurrent: 0,
            textModal: false,
            confirmModal: false,
            detailObj: {},
            employee: {},
            flowList: {},
            statusBarHeight: 48, // 状态栏高度
            titleBarHeight: 48, // 标题栏高度
            navBarHeight: 124, // 导航栏总高度
            vhtml: "人才姓名<span style='color:#4B5EB4'>【张天天】</span>，已签署",
            callPhone: "",
            lastPath:"",
        };
    },
    beforeMount() {
        const self = this;
        self.navBarHeight = self.statusBarHeight + self.titleBarHeight;
    },
    methods: {
        showTextModal() {
            this.$router.push({
                path: "/indexComplain",
                query:{
                    id: this.id
                }
            })
            // this.textModal = true;
        },
        handleCancel() {
            this.textModal = false;
        },
        backClick() {
            // console.log('backClick----',this.lastPath);
            if(this.lastPath == "" || this.lastPath == "/index" || this.lastPath == "/"){
                this.$router.replace({
                    path:"/index",
                    query:{
                        status: 1
                    }
                })
            }else{
                this.$router.go(-1);
            }
            
        },
        getContractUrl() {
            let { status, signUrl } = this.detailObj;
            console.log(status,signUrl);
            if ((status != 1 && status != 2 ) || !signUrl) {
                return;
            }
            window.location.href = signUrl;
        },
        handleTextConfirm(text) {
            text.contractId = this.id;
            sendComplaint(text).then((res) => {
                let { status, statusCode, statusMessage } = res;
                if (status) {
                    this.confirmModal = true;
                } else {
                    this.$toast({
                        message:statusMessage
                    })
                    return;
                }
            });
            this.textModal = false;

        },
        handleConfirmCancel() {
            this.confirmModal = false;
        },
        callTelephone() {
            this.callPhone = 'tel:' + this.detailObj.employeeMobile
        },
        goMineOpinion() {
            this.$router.push({
                path: "/opinion"
            })
        },
        handleFlowList(data) {
            // console.log('flowMessage',data[0].flowMessage);
            for (let i = 0; i < data.length; i++) {
                let str = data[i].flowMessage;
                // let str = "人才姓名<span style='color:#4B5EB4'>【张天天】</span>，已签署";
                let start = str.indexOf("【");
                str = this.insertStr(
                    str,
                    start,
                    "<span style='color:#4B5EB4'>"
                );
                let end = str.indexOf("】") + 1;
                str = this.insertStr(str, end, "</span>");
                data[i].flowMessage = str;
            }
            return data;
        },
        insertStr(soure, start, newStr) {
            return soure.slice(0, start) + newStr + soure.slice(start);
        },
    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            // beforeRouteEnter不能通过this访问组件实例，但是可以通过 vm 访问组件实例
            // console.log('from.path----',from.path);
            vm.lastPath = from.path;
        })
    },
    created() {
        let { id } = this.$route.query;
        // id = "536816201519529984";
        this.id = id;
        getContractById({ id }).then((res) => {
            let { status } = res;
            if (status) {
                let { flowList } = res.data;
                res.data.amount = res.data.amount ? regFenToYuan(res.data.amount) : res.data.amount
                res.data.expiresIn = res.data.expiresIn ? parseTime(res.data.expiresIn) : res.data.expiresIn
                this.detailObj = res.data;
                // this.employee = res.data.employee;
                // this.flowList = this.handleFlowList(flowList);
            }
        });
    },
    mounted () {
        // let state = {
        //     title:'',
        //     url: this.lastPath
        // }
        // window.history.pushState(state, state.title, state.url)

        // window.onpopstate = () => {
        //     // 输入要返回的上一级路由地址
        //     console.log('window.onpopstate--');
        //     if(this.lastPath == "" || this.lastPath == "/index"){
        //         this.$router.replace({
        //             path:"/index",
        //             query:{
        //                 status: 1
        //             }
        //         })
        //     }else{
        //         this.$router.go(-1);
        //     }
        // }
    },
};
</script>

<style >

div {
    box-sizing: border-box;
}
/* 头部 */
.comp-navbar {
    width: 100vw;
    background: #fff;
}
.header-bg{
    width: 100%;
    height: 124px;
}
.bar-nav{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 30;
}
.nav-titlebar{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}
.nav-titlebar .img{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    margin: auto;
    width: 10px;
    height: 18px;
}
.nav-titlebar .text{
    font-size: 16px;
    color: #fff;
}
.nav-titlebar .img img{
    width: 10px;
    height: 18px;
}
.part-circle{
    height: 62px;
    width: 100%;
}


.contract-card {
    position: relative;
    width: 351px;
    /* height: 203px; */
    border-radius: 6px;
    margin: 0 auto;
    margin-top: -30px;
    padding: 20px 20px 0 20px;
}
.contract-card .title {
    display: flex;
    height: 30px;
    align-items: center;
    margin-bottom: 5px;
}
.contract-card .title img {
    width: 34px;
    height: 30px;
}
.contract-card .title > div {
    font-size: 18px;
    color: #222222;
    font-weight: 500;
}

.contract-card .info {
    margin-top: 4px;
    color: #999;
    font-size: 13px;
}
.contract-card .info span {
    color: #ff3000;
}
.contract-card .pc {
    position: absolute;
    top: 0;
    right: 0;
    /* position: relative; */
}
.contract-card .pc img {
    width: 67px;
    height: 22.5px;
}
.contract-card .pc div {
    font-size: 11px;
    font-weight: 500;
    color: #b87942;
    position: absolute;
    top: 2.5px;
    right: 10px;
    z-index: 10;
}
.contract-card .no-wait img {
    width: 99px;
    height: 67px;
}

.contract-card .opt {
    margin-top: 18px;
    height: 44px;
    border-top: 1px solid #e9eaf0;
}
.contract-card .opt > div {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contract-card .opt > div:nth-of-type(1){
    border-right: 1px solid #E9EAF0;;
}
.contract-card .opt > div img {
    width: 14px;
    height: 14px;
}
.contract-card .opt > div .opt-title {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #4b5eb4;
    margin-left: 4px;
}
.contract-card .opt > div span {
    margin-left: 4px;
    font-size: 11px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #737375;
}
.contract-card .opt a{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contract-process {
    width: 100%;
    /* height: 450px; */
    padding: 0 17px;
    width: 351px;
    margin: 20px auto;
    margin-bottom: 0;
    /* margin-bottom: 50px; */
}
.contract-process .item {
    height: 94px;
    padding-top: 21px;
    border-bottom: 1px solid #edf1f4;
}
.contract-process .item .item-top {
    height: 23px;
}
.contract-process .item .item-top > img {
    width: 23px;
    height: 23px;
    vertical-align: middle;
}
.contract-process .item .item-top span {
    font-size: 17px;
    margin-left: 6.5px;
    font-weight: 600;
}
.contract-process .item-bottom {
    margin-top: 11.5px;
    align-items: center;
    justify-content: space-between;
}
.contract-process .item-bottom > span {
    font-size: 14px;
    color: #999999;
}
.contract-process .item-bottom img {
    width: 8px;
    height: 12px;
}
.contract-process .item-bottom .contcat {
    color: #116ed9;
    font-size: 14px;
    margin-right: 9px;
}
.contract-process .sign-process {
    padding-top: 21.5px;
}
.sign-process .top {
    height: 23px;
}
.sign-process .top > img {
    width: 23px;
    height: 23px;
    vertical-align: middle;
}
.sign-process .top span {
    font-size: 17px;
    /* margin-left: 6.5px; */
    font-weight: 600;
    color: #2e2e2f;
}
.sign-process .step {
    margin-top: 19px;
    margin-left: -10px;
}
.sign-process .step .time {
    color: #999999;
    font-size: 14px;
    font-weight: 100;
}
.sign-process .step .content {
    color: #222;
    font-size: 14px;
    font-weight: 0;
    width: 275px;
    margin-top: 10px;
}
.contract-detail .option {
    margin-top: 24px;
    /* padding-left: 31.5px; */
    margin-bottom: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contract-detail .option div {
    /* width: 160px; */
    width: 90%;
    height: 44px;
    line-height: 44px;
    text-align: center;
    border-radius: 22px;
    font-size: 16px;

}
.contract-detail .option div {
    padding: 0 0 !important;
}
.contract-detail .option div img {
    width: 19px;
    height: 20.5px;
}
.contract-detail .option .complaint {
    margin: 0 7px;
    background: #fff;
    color: #4c5fb4;
    box-shadow: 0px 8px 24px 0px rgba(124, 138, 194, 0.14);
}
.contract-detail .option .opinion {
    margin: 0 7px;
    background: #116ed9;
    color: #fff;
    background: linear-gradient(-90deg, #4b62bd, #5267bf);
}

/* 新步骤图 */
.step-new {
    margin-top: 18px;
    padding-bottom: 30px;
}
.step-new .step-item {
    padding-bottom: 25px;
    /* border-left: 1px dashed #E8E8E8; */
    position: relative;
}
.step-new .step-item .step-line {
    width: 20px;
    height: 10px;
    display: flex;
    align-items: center;
    background: #fff;
    overflow: hidden;
}
.step-new .step-line .step-ico {
    width: 10px;
    height: 10px;
    background: #e8e8e8;
    border-radius: 50%;
    position: relative;
    z-index: 2;
}
.step-new .step-line .line {
    background: #e8e8e8;
    position: absolute;
    height: 100%;
    top: 0;
    left: -7.5px;
    margin: 0 0 0 12px;
    width: 1px;
}

.step-new .step-item .text {
    font-size: 13px;
    margin-left: 5px;
}
.step-new .step-item .text .title {
    height: 18px;
    line-height: 10px;
    color: #b0b0b0;
    font-size: 13px;
}
.step-new .step-item .text .content {
    width: 270px;
    line-height: 25px;
    font-size: 14px;
    color: #575759;
}
</style>
